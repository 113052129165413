import * as React from 'react';
import { FlexItem, Button } from '@fluentui/react-northstar';
import { ChevronStartIcon, ChevronEndIcon } from '@fluentui/react-icons-northstar';
import { initializeIcons } from '@fluentui/react';

export interface IPaginationProps {
    callbackFromParent: (newPageNumber: number) => void;
    entitiesLength: number;
    activePage: number;
    numberOfContents: number;
}

class Pagination extends React.Component<IPaginationProps, {}> {

    constructor(props: any) {
        super(props);
        initializeIcons();
    };

    public render(): JSX.Element {
        let numberOfPages = Math.ceil(this.props.entitiesLength / this.props.numberOfContents);

        const createPagination = (params: any) => {
            const {
                currentPage,
                numberOfButtons
            } = params;

            if ((currentPage - 1) > numberOfPages || currentPage < 1)
                return {
                    pagination: [],
                    currentPage
                };

            const buttons = Array(numberOfPages)
                .fill(1)
                .map((e, i) => e + i);
            const sideButtons =
                numberOfButtons % 2 === 0 ? numberOfButtons / 2 : (numberOfButtons - 1) / 2;

            const calculLeft = (rest = 0) => {
                return {
                    array: buttons
                        .slice(0, currentPage - 1)
                        .reverse()
                        .slice(0, sideButtons + rest)
                        .reverse(),
                    rest: function () {
                        return sideButtons - this.array.length;
                    }
                };
            };

            const calculRight = (rest = 0) => {
                return {
                    array: buttons.slice(currentPage).slice(0, sideButtons + rest),
                    rest: function () {
                        return sideButtons - this.array.length;
                    }
                };
            };

            const leftButtons = calculLeft(calculRight().rest()).array;
            const rightButtons = calculRight(calculLeft().rest()).array;

            return {
                pagination: [...leftButtons, currentPage, ...rightButtons],
                currentPage
            };
        };

        const { pagination } = createPagination({
            numberOfContents: this.props.entitiesLength,
            numberOfButtons: 8,
            currentPage: (this.props.activePage + 1)
        });

        const paginationRender = pagination.map((page: any, index: number) => {
            return (
                (this.props.activePage + 1) == page ?
                    <div key={index}>
                        <Button
                            content={page}
                            onClick={() => this.props.callbackFromParent((page - 1))}
                            primary
                            styles={{ border: 'none', boxShadow: 'none' }}
                            iconOnly
                            className="active"
                        />
                    </div>
                    :
                    <div key={index}>
                        <Button
                            content={page}
                            onClick={() => this.props.callbackFromParent((page - 1))}
                            secondary
                            styles={{ border: 'none', boxShadow: 'none' }}
                            iconOnly
                        />
                    </div>
            )
        })

        return (
            <FlexItem>
                <>
                    <Button
                        content={<ChevronStartIcon />}
                        onClick={() => this.props.callbackFromParent(this.props.activePage - 1)}
                        secondary
                        styles={{ border: 'none', boxShadow: 'none' }}
                        iconOnly
                        disabled={this.props.activePage == 0}
                    />
                    {paginationRender}
                    <Button
                        content={<ChevronEndIcon />}
                        onClick={() => this.props.callbackFromParent(this.props.activePage + 1)}
                        secondary
                        styles={{ border: 'none', boxShadow: 'none' }}
                        iconOnly
                        disabled={(this.props.activePage + 1) == numberOfPages}
                    />
                </>
            </FlexItem>
        );
    }
}

export default Pagination;