import React from 'react';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from "react-i18next";
import { Menu, MoreIcon, SendIcon, OpenOutsideIcon, EditIcon, ClipboardCopiedToIcon, TrashCanIcon } from '@fluentui/react-northstar';
import { app, dialog, UrlDialogInfo } from "@microsoft/teams-js";

import { getBaseUrl } from '../../configVariables';
import { selectMessage, getMessagesList, getMessagesCount, getDraftMessagesList, getDraftMessagesCount, getScheduledMessagesList, getScheduledMessagesCount } from '../../actions';
import { deleteDraftNotification, duplicateDraftNotification, sendPreview } from '../../apis/messageListApi';
import { TFunction } from "i18next";
import MessagesTabs from "../Constants/messagesTabs";
import { getNotificationTypeUrl, getNotificationTypeTaksModuleSize } from "../../utility/notification-types";
import { Utils } from '../../utility/Utils';
import { MediaType } from '../../utility/SharedEnum';

export interface OverflowProps extends WithTranslation {
    message: any;
    styles?: object;
    title?: string;
    selectMessage?: any;
    getMessagesList?: any;
    getMessagesCount?: any;
    getDraftMessagesList?: any;
    getDraftMessagesCount?: any;
    getScheduledMessagesList?: any;
    getScheduledMessagesCount?: any;
    setActiveTabId?: any;
    setLoaderMessages?: any;
    offset: number;
    limit: number;
}

export interface OverflowState {
    teamsTeamId?: string;
    teamsChannelId?: string;
    teamId?: string;
    menuOpen: boolean;
    isSending: boolean;
}

class Overflow extends React.Component<OverflowProps, OverflowState> {
    readonly localize: TFunction;
    constructor(props: OverflowProps) {
        super(props);
        this.localize = this.props.t;
        this.state = {
            teamsChannelId: '',
            teamsTeamId: '',
            teamId: '',
            menuOpen: false,
            isSending: false,
        };
    }

    public componentDidMount() {
        void app.initialize().then(() => {
            void app.getContext().then((context: any) => {
                this.setState({
                    teamsTeamId: context.team.internalId,
                    teamsChannelId: context.channel.id,
                    teamId: context.team.groupId ?? context.channel.ownerGroupId,
                });
            });
        });
    }

    public render(): JSX.Element {
        const items = [
            {
                key: 'more',
                icon: <MoreIcon outline={true} />,
                menuOpen: this.state.menuOpen,
                active: this.state.menuOpen,
                indicator: false,
                menu: {
                    items: [
                        {
                            key: 'send',
                            icon: <SendIcon outline />,
                            content: this.localize("Send"),
                            onClick: (event: any) => {
                                event.stopPropagation();
                                this.setState({
                                    menuOpen: false,
                                    isSending: true,
                                });
                                let url = getBaseUrl() + "/sendconfirmation/" + this.props.message.id + "?locale={locale}";

                                this.onOpenTaskModule(null, url, this.localize("SendConfirmation"), "");
                            }
                        },
                        {
                            key: 'preview',
                            icon: <OpenOutsideIcon outline />,
                            content: this.localize("PreviewInThisChannel"),
                            onClick: (event: any) => {
                                event.stopPropagation();
                                this.setState({
                                    menuOpen: false,
                                });
                                let payload = {
                                    draftNotificationId: this.props.message.id,
                                    teamsTeamId: this.state.teamsTeamId,
                                    teamsChannelId: this.state.teamsChannelId,
                                }
                                sendPreview(payload).then((response) => {
                                    return response.status;
                                }).catch((error) => {
                                    return error;
                                });
                            }
                        },
                        {
                            key: 'edit',
                            icon: <EditIcon outline />,
                            content: this.localize("Edit"),
                            onClick: (event: any) => {
                                let qs = new URLSearchParams(window.location.search);
                                let noSendAll = qs.get("noSendAll") ? "&noSendAll=" + qs.get("noSendAll") : "";

                                event.stopPropagation();
                                this.setState({
                                    menuOpen: false,
                                });

                                const type = this.props.message.type;

                                let url = getBaseUrl() + getNotificationTypeUrl(this.props.message.type, this.props.message.mediaType) + this.props.message.id + "?locale={locale}" + noSendAll;
                                this.onOpenTaskModule(null, url, this.localize("EditMessage"), type);
                            }
                        },
                        {
                            key: 'duplicate',
                            icon: <ClipboardCopiedToIcon outline />,
                            content: this.localize("Duplicate"),
                            disabled: this.props.message.mediaType == MediaType.Video,
                            onClick: (event: any) => {
                                event.stopPropagation();
                                this.props.setLoaderMessages(true);
                                this.setState({
                                    menuOpen: false,
                                });
                                this.duplicateDraftMessage(this.props.message.id).then(() => {
                                    this.props.getDraftMessagesList(this.props.offset, this.props.limit, this.state.teamId);
                                    this.props.getDraftMessagesCount(this.state.teamId);
                                });
                            }
                        },
                        {
                            key: 'delete',
                            icon: <TrashCanIcon outline />,
                            content: this.localize("Delete"),
                            onClick: (event: any) => {
                                event.stopPropagation();     
                                this.props.setLoaderMessages(true);
                                this.setState({
                                    menuOpen: false,
                                });
                                this.deleteDraftMessage(this.props.message.id).then(() => {
                                    this.props.getDraftMessagesList(this.props.offset, this.props.limit, this.state.teamId);
                                    this.props.getDraftMessagesCount(this.state.teamId);
                                });
                            }
                        },
                    ],
                },
                onMenuOpenChange: (e: any, { menuOpen }: any) => {
                    this.setState({
                        menuOpen: menuOpen
                    });
                },
            },
        ];

        return <Menu className="menuContainer" iconOnly items={items} styles={this.props.styles} title={this.props.title} />;
    }

    private onOpenTaskModule = (event: any, url: string, title: string, type: string) => {        
        let size = getNotificationTypeTaksModuleSize(type);

        let dialogInfo: UrlDialogInfo = {
            url: url,
            title: title,
            size: {
                height: size?.height,
                width: size?.width,
            },
            fallbackUrl: url,
        }

        let submitHandler = (result: any) => {
            if (result?.err) {
                return;
            }

            if (this.state.isSending) {
                this.props.setActiveTabId(MessagesTabs.sendings);
                this.props.getMessagesList(this.props.offset, this.props.limit, this.state.teamId);
                this.props.getMessagesCount(this.state.teamId);
            }
            else {
                var activeTab = Utils.getActiveTab();
                if (activeTab) {
                    this.props.setActiveTabId(activeTab);

                    if (activeTab == MessagesTabs.scheduleds) {
                        this.props.getScheduledMessagesList(this.props.offset, this.props.limit, this.state.teamId);
                        this.props.getScheduledMessagesCount(this.state.teamId);
                        return;
                    }
                }             

                this.props.setLoaderMessages(true);
                this.props.getDraftMessagesList(this.props.offset, this.props.limit, this.state.teamId);
                this.props.getDraftMessagesCount(this.state.teamId);
            }
        };

        dialog.url.open(dialogInfo, submitHandler);
    }

    private duplicateDraftMessage = async (id: number) => {
        try {
            await duplicateDraftNotification(id);
        } catch (error) {
            return error;
        }
    }

    private deleteDraftMessage = async (id: number) => {
        try {
            await deleteDraftNotification(id);
        } catch (error) {
            return error;
        }
    }
}

const mapStateToProps = (state: any) => {
    return { messages: state.draftMessagesList, selectedMessage: state.selectedMessage };
}

const overflowWithTranslation = withTranslation()(Overflow);
export default connect(mapStateToProps, { selectMessage, getDraftMessagesList, getDraftMessagesCount, getMessagesList, getMessagesCount, getScheduledMessagesList, getScheduledMessagesCount })(overflowWithTranslation);