export class UtilsConstants {

    public static readonly ACTION_INSTANCE_INDEFINITE_EXPIRY = -1;

    // ASCII value for carriage return
    public static readonly CARRIAGE_RETURN_ASCII_VALUE = 13;
    public static readonly ESCAPE_ASCII_VALUE = 27;

    // some OS doesn't support long filenames, so capping the action's title length to this number
    public static readonly ACTION_RESULT_FILE_NAME_MAX_LENGTH: number = 50;

    public static readonly colors = {
        defaultBackgroundColor: "#fff",
        darkBackgroundColor: "#252423",
        contrastBackgroundColor: "black"
    };

    public static readonly rowsLoader = [
        { max: 700, rows: 4 },
        { max: 900, rows: 8 },
        { max: Infinity, rows: 9 },
    ];
}